import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import './style.css';
import tripLogo from '../image/Trip_logo_Grey.png';
import tripUP2 from '../image/trip_b_pic1_cropped.jpg';
import tripUP from '../image/trip_b_mobile.png';

// import kn from '../image/knowladgebridge.png';
import kbiLogo from '../image/logo_kb.png';
import BTN from '../image/button.png';
// import torrent from '../image/torrent-hd.png';
import { site_ip } from '../globalSetting';
import axios from 'axios';
import publicIp from 'public-ip';
import { Link } from 'react-router-dom';
import RedeemAccess from './common/RedeemAccess';
import BasicInformation from './common/BasicInformation';
import SubscriptionInformation from './common/SubscriptionInformation'
import ImageMapper from 'react-img-mapper';
const { detect } = require('detect-browser');
const browser = detect();
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_email: '',
      password: '',
      userdata: [],
      browsername: '',
      browseros: '',
      browserversion: '',
      ipaddress: '',
      login_datetime: new Date(),
      rememberMe: false,
      trialPopup:false,
      redeemAccess:false,
      basicInformation:false,
      subscriptionInformation:false,
      activeAccessCode:'',
      institutionId:''
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeuserEmail = this.onChangeuserEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleUserLogin = this.handleUserLogin.bind(this);
    this.child = React.createRef();
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.loginUser = this.loginUser.bind(this);
  }

  checkJwt() {
    let token = localStorage.getItem('jwt');
    if (token) {
      this.setState({ rememberMe: true });
    } else {
      this.setState({ rememberMe: false });
    }
  }
  loginUser(accessToken) {
    if (accessToken) {
      if (this.state.rememberMe === false) {
        localStorage.setItem('jwt', accessToken);
      }
      this.setState({ rememberMe: true });
    } else {
      this.setState({ rememberMe: false });
    }
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }
  onChangeuserEmail(e) {
    this.setState({
      user_email: e.target.value,
    });
  }

  async getIP() {
    await publicIp.v4().then((res) => this.setState({ ipaddress: res }));
  }
  handleUserLogin(user) {
    let objectThis = this;
    if (browser) {
      objectThis.setState({ browsername: browser.name });
      objectThis.setState({ browserversion: browser.version });
      objectThis.setState({ browseros: browser.os });
    }
    axios({
      method: 'post',
      url: site_ip + '/setUserLogin',
      data: {
        user_id: user?.user_id,
        user_ip: objectThis.state.ipaddress,
        user_browser: objectThis.state.browsername,
        user_device: objectThis.state.browseros,
        login_datetime: objectThis.state.login_datetime,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data) {
          localStorage.setItem(
            'loginid',
            JSON.stringify(response.data.loginid)
          );
          if(user?.first_time_login_ind === 'Y'){
            objectThis.setState({basicInformation: true})
          }else{
            window.location.href = '/Dashboard';
          }
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  }

  handleLogin() {
    let objectThis = this;
    axios({
      method: 'post',
      url: site_ip + '/auth/siteSignin',
      data: {
        user_email: objectThis.state.user_email,
        password: objectThis.state.password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data.accessToken) {
          if(response.data.ContentImages.length > 0){
            localStorage.setItem(
              'mobileImg',
              response.data.ContentImages[0]?.brand_image_mobile
            );
            localStorage.setItem(
              'desktopImg',
              response.data.ContentImages[0]?.brand_image
            );
          }else{
            localStorage.setItem(
              'mobileImg',
              ""
            );
            localStorage.setItem(
              'desktopImg',
              ""
            );
          }
          
          localStorage.setItem(
            'userdata',
            JSON.stringify(response.data.content)
          );
          objectThis.handleUserLogin(response.data.content);
           
          objectThis.loginUser(response.data.accessToken);
        }
      })
      .catch(function (error) {
        console.log('error1' + error);
      });
  }
  componentDidMount() {
    this.checkJwt();
    this.getIP();
  }
  toggleRememberMe() {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  }

  redemptionSuccessFull(val, institution_id){
    this.setState({activeAccessCode: val})
    this.setState({institutionId: institution_id})
    this.setState({redeemAccess: false})
    this.setState({basicInformation: true})
  }

  tabClick(tab){
    if(tab === 1){
      this.setState({redeemAccess: true})
    }else if(tab === 2){
      localStorage.removeItem('userdata');
      this.setState({activeAccessCode: ''})
      this.setState({basicInformation: true})
    }else if(tab === 3){
      window.location.href = "/know-about-trip/videos"
    }else if(tab === 4){
      this.setState({trialPopup: true})
    }else if(tab === 5){
      this.setState({subscriptionInformation: true})
    }
  }
  
  render() {
    return (
      <Container fluid className="h-100">
        <div>
          <Row>
            <Col style={{ paddingRight: '0px' }}>
              <div className="sponser_logo">
                {/* <img
                  src={torrent}
                  alt="sponser logo"
                  className="sponser_logo"
                /> */}
                {/* <img
                  className="trip_up_to "
                  alt="Responsive image"
                  src={tripLogo}
                /> */}
              </div>
            </Col>
            <Col style={{ paddingLeft: '0px' }}>
              <div className="right_pic_collection_up">
                <div
                  className="text-right"
                  style={{
                    marginBottom: '-20px',
                    marginRight: '29px',
                    fontSize: '0.8em',
                  }}
                >
                  Partnering with
                </div>
                <img
                  className="trip_up_to "
                  alt="Responsive image"
                  src={tripLogo}
                />
                <span className="vr"></span>
                <img
                  className="knowladgebridge_pic"
                  alt="Responsive image"
                  src={kbiLogo}
                />
              </div>
            </Col>
          </Row>
          
          <br />
          <div>
            <p className="trip_medical">Trip Medical Database</p>
            <br />
            <p className="a_smart_fast">
              A smart, fast tool to find high-quality clinical research evidence
            </p>
          </div>
          <div style={{ position: 'relative' }} className="forBgScreenOnly">
            <Mapper tabClick={(val) => this.tabClick(val)} />
            
            <div className="loginBoxResp">
              <Form>
              <div class="text-right">
                  <Link
                    to="/OTPlogin"
                    className="otplogin btn btn-primary mb-2"
                  >
                    Login With OTP
                  </Link>
                </div>
                <div class="text-right">
                  <strong>OTP Facility available ONLY for INDIAN users</strong>
                </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    value={this.state.user_email}
                    onChange={this.onChangeuserEmail}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                  />
                </Form.Group>
                <div class="d-flex align-items-center">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Remember me"
                      id="rememberMe"
                      ref="rememberMe"
                      checked={this.state.rememberMe}
                      onChange={this.toggleRememberMe}
                    />
                  </Form.Group>
                  <div class="ml-auto mb-3">
                    <Link to={'/forgot_password'}> Forgot password ? </Link>
                  </div>
                </div>
                <Button className="login-submit-btn" variant="light" onClick={this.handleLogin}>
                  <img src={BTN} />
                </Button>
              </Form>
            </div>

            <a href="#" className="btn bg-transparent btn_press"></a>
          </div>
          <Row className='home-tab-bar'>
            <Col>
                <div className="tab active">
                  <button onClick={() => null}>Registered Users <br/>Login Here</button>
                </div>
            </Col>
            <Col>
                <div className="tab">
                  <button onClick={() => this.setState({redeemAccess: true})}>Redeem Access Code</button>
                </div>
            </Col>
            <Col>
            <div className="tab">
                  <button onClick={() => {
                    localStorage.removeItem('userdata');
                    this.setState({basicInformation: true})
                  }}>New User<br/>Register Here</button>
                </div>
            </Col>
            <Col>
                <div className="tab">
                  <button onClick={() => window.location.href = "/know-about-trip/videos"}>Know About Trip</button>
                </div>
              </Col>
            <Col>
                <div className="tab">
                  <button onClick={() => this.setState({subscriptionInformation: true})}>Purchase One Year <br/> Subscription</button>
                </div>   
            </Col>
          </Row>
          <div style={{ position: 'relative' }} className="forSmScreenOnly">
            <img
              className="middl_big_pic img-fluid mobile"
              alt="Responsive image"
              src={tripUP}
            />
            <div className="loginFormSmall">
              <Form>
              <div class="text-right">
                  <Link
                    to="/OTPlogin"
                    className="otplogin btn btn-primary mb-2"
                  >
                    Login With OTP
                  </Link>
                </div>
                <div class="text-right">
                  <strong>OTP Facility available ONLY for INDIAN users</strong>
                </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    value={this.state.user_email}
                    onChange={this.onChangeuserEmail}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Remember me"
                    id="rememberMe"
                    ref="rememberMe"
                    checked={this.state.rememberMe}
                    onChange={this.toggleRememberMe}
                  />
                </Form.Group>
                <Button variant="light" onClick={this.handleLogin}>
                  <img src={BTN} width="100%" />
                </Button>
              </Form>
            </div>

            <a href="#" className="btn bg-transparent btn_press"></a>
          </div>
          
          <div className="pram_brand_1234 text-center" style={{alignItems:'center', justifyContent:'center'}}>
            
          </div>
        </div>
        <TrialPopup show={this.state.trialPopup} handleClose={()=>this.setState({trialPopup:false})} />
        <RedeemAccess show={this.state.redeemAccess} handleClose={()=>this.setState({redeemAccess:false})} redemptionSuccess={(val, institution_id) => this.redemptionSuccessFull(val, institution_id)} />
        {this.state.basicInformation ? 
        <BasicInformation show={this.state.basicInformation} handleClose={()=>this.setState({basicInformation:false})} activeAccessCode={this.state.activeAccessCode} institutionId={this.state.institutionId} /> : null }
        <SubscriptionInformation show={this.state.subscriptionInformation} handleClose={()=>this.setState({subscriptionInformation: false})} activeAccessCode={this.state.activeAccessCode} institutionId={this.state.institutionId} />
      </Container>
    );
  }
}


const TrialPopup = (props) => {
  
  const [mystates, setMyStates] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);  
  const fetchStates = (e) => {
    fetch(`${site_ip}/getStatesByCountry/1`)
      .then((res) => res.json())  
      .then((result) => {
        setMyStates(result.content)
      });
  }

  useEffect(()=>{
    fetchStates()
  }, [])

  const initialValues = {
    name: '',
    designation: '',
    department: '',
    street_number: '',
    street_name: '',
    city: '',
    institution_name: '',
    state_id: '',
    postal_code: '',
    telephone: '',
    email: '',
    comments: ''
  };
  const [values, setValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError(false)
    // if(name === 'state_id'){
    //   fetchCities(value)
    // }
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const pincode = (mobile) => {
    var phoneno = /^\d{6}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const submitForm = () => {
    if(values.name === ''){
      setError(true)
      return
    }else if(!validateEmail(values.email)){
      setError(true)
      return
    }else if(!phonenumber(values.telephone)){
      setError(true)
      return
    }else if(values.designation === ''){
      setError(true)
      return
    }else if(values.department === ''){
      setError(true)
      return
    }else if(values.street_number === ''){
      setError(true)
      return
    }else if(values.institution_name === ''){
      setError(true)
      return
    }else if(values.city === ''){
      setError(true)
      return
    }else if(values.state_id === ''){
      setError(true)
      return
    }else if(!pincode(values.postal_code)){
      setError(true)
      return
    }else{
      axios({
        method: 'post',
        url: `${site_ip}/setFreeTrial`,
        data: {
          name: values.name,
          designation: values.designation,
          department: values.department,
          street_number: values.street_number,
          street_name: values.street_name,
          city: values.city,
          institution_name: values.institution_name,
          state_id: values.state_id,
          postal_code: values.postal_code,
          telephone: values.telephone,
          email: values.email,
          comments: ''
        },
        dataType: 'json',
      })
        .then(function (response) {
          setValues(initialValues);
          setSuccess(true);
        })
        .catch(function (error) {
          alert("Something went wrong. Please try again.");
        });
    }
  };

  return (
    <>
      <Modal size={"xl"} show={props.show} onHide={props.handleClose}>
        <Modal.Header >
          <Modal.Title>
            <p style={{fontSize:"24px", color:"#543767", marginBottom:"0px", fontWeight:"bold"}}>Request for a Trial</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            {error ? 
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              <Alert.Heading><p style={{fontSize:"16px", marginBottom:"0px"}}>Error! Please enter all/valid values.</p></Alert.Heading>
            </Alert> : null }
            {success ? 
            <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
              <Alert.Heading><p style={{fontSize:"16px", marginBottom:"0px"}}>Thank you very much for showing interest in TRIP. We will soon get back to you.</p></Alert.Heading>
            </Alert> : null }
            <p style={{fontSize:"14px", color:"#000000", fontWeight:"bold"}}>Personal Details</p>
          </Col>
        </Row>  
        <Row>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12 "
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="tel"
                  placeholder="Mobile Number"
                  name="telephone"
                  maxLength={10}
                  value={values.telephone}
                  onChange={handleChange}
                />
              </Form.Group>
        </Row>
        <Row>
          <Col><p style={{fontSize:"14px", color:"#000000", fontWeight:"bold"}}>Other Details</p></Col>
        </Row>
        <Row>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Designation"
                  name="designation"
                  value={values.designation}
                  onChange={handleChange}
                />
              </Form.Group>
              
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Department"
                  name="department"
                  value={values.department}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Name Of University/Institute"
                  name="institution_name"
                  value={values.institution_name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Address 1"
                  name="street_number"
                  value={values.street_number}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Address 2"
                  name="street_name"
                  value={values.street_name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  as="select"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {mystates?.map((value, index) => (
                    <option key={index} value={value.id}>{value.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Pin code"
                  name="postal_code"
                  value={values.postal_code}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col><p style={{fontSize:"10px", color:"#ff0000", fontWeight:"bold"}}>* All fields are mandatory</p></Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => submitForm()}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

 

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const Mapper = props => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const URL = '/assets/trip_b_pic1_cropped.jpg';
  const MAP = {
    name: 'my-map',
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: areas  //'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.json',
  };

  return (
    <div>
      <ImageMapper active={false} src={URL} map={MAP} responsive={true} parentWidth={windowDimensions?.width - 15} onClick={(val) => props?.tabClick(val?.name)} />
    </div>
  )
}

const areas = [
  {
    "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
    "title": "Hardwood",
    "shape": "rect",
    "name": 1,
    // "fillColor": "#ffffffff",
    // "strokeColor": "black",
    "coords": [
      550,
      65,
      800,
      5,
    ]
  },
  {
    "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
    "title": "Hardwood",
    "shape": "rect",
    "name": 2,
    // "fillColor": "#ffffffff",
    "strokeColor": "black",
    "coords": [
      820,
      65,
      1070,
      5,
    ]
  },
  {
    "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
    "title": "Hardwood",
    "shape": "rect",
    "name": 3,
    // "fillColor": "#ffffffff",
    "strokeColor": "black",
    "coords": [
      1090,
      65,
      1340,
      5,
    ]
  },
  {
    "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
    "title": "Hardwood",
    "shape": "rect",
    "name": 4,
     
    "strokeColor": "black",
    "coords": [
      1360,
      65,
      1610,
      5,
    ]
  },
  {
    "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
    "title": "Hardwood",
    "shape": "rect",
    "name": 5,
    
    "strokeColor": "black",
    "coords": [
      1630,
      65,
      1880,
      5,
    ]
  },
]