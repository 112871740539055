import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { site_ip } from '../../../globalSetting';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../common/BackButton';
function Login() {
  const navigate = useNavigate();
  const params = useParams();
  const initialValues = { 
    salutation: "",
    first_name: "",
    last_name: "",
    user_email: "",
    user_mobile: "",
    password: '',
    city_id: "",
    state_id: "",
    country_id: 1,
    subject_id: "",
    university_name: "",
    user_login: "",
    first_time_login_ind:'Y',
    full_access_ind: "",
    coupon_code: "",
    subject_id: "",
    zipcode: "",
    gender: "",
    publisher_id: "",
    institution_id: "",
    password: "",
    address:""
  };
  const [values, setValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "state_id"){
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
        city_id:""
      }));
      getCities(value)
    }if(name === "user_mobile"){
      if(validPositiveNumber(value)){
        setValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }else{
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() =>{
    getSpecilization();
    getStates();
    getInstitution();

    if(params?.id !== undefined){
      getCurrentUser(params?.id)
    }

  }, [])

  const getCurrentUser = (id) => {
    axios({
      method: 'get',
      url: site_ip + '/auth/getSiteUserById/'+id,
      dataType: 'json',
    })
      .then(function (response) {
        setValues(response.data.Data[0])
        if(response.data.Data[0]?.state_id !== ""){
          getCities(response.data.Data[0]?.state_id)
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [speciality, setSpeciality] = useState([])
  const getSpecilization = () => {
    axios({
      method: 'get',
      url: site_ip + '/getSubjects',
      dataType: 'json',
    })
      .then(function (response) {
        setSpeciality(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [institution, setInstitution] = useState([])
  const getInstitution = () => {
    axios({
      method: 'get',
      url: site_ip + '/getInstitutions',
      dataType: 'json',
    })
      .then(function (response) {
        setInstitution(response.data.Data)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [states, setStates] = useState([])
  const getStates = () => {
    axios({
      method: 'get',
      url: site_ip + '/getStatesByCountry/1',
      dataType: 'json',
    })
      .then(function (response) {
        setStates(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [cities, setCities] = useState([])
  const getCities = (id) => {
    axios({
      method: 'get',
      url: site_ip + '/getCities/'+ id,
      dataType: 'json',
    })
      .then(function (response) {
        setCities(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const validateEmail = (mail) => {
    if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            mail
        )
    ) {
        return true;
    }
    return false;
};

const validPositiveNumber = (number) => {
    if (number === "") {
        return true;
    }
    var positiveNumber = /^(0|\+?[1-9]\d*)$/;
    if (String(number).match(positiveNumber)) {
        return true;
    } else {
        return false;
    }
};

console.log(values?.user_mobile?.length)
  const handleSubmit = () => {
    if(values?.first_name.trim() === ''){
      alert("Please enter your first name.")
      return
    }if(values?.last_name.trim() === ''){
      alert("Please enter your last name.")
      return
    }else if(!validateEmail(values?.user_email)){
      alert("Please enter a valid email address.")
      return
    }else if(values?.user_mobile?.toString().length !== 10){
      alert("Please enter a valid mobile number.")
      return
    }else if(values?.subject_id === ''){
      alert("Please select your specialty.")
      return
    }else if(values?.institution_id === ''){
      alert("Please select your institution.")
      return
    }else if(values?.address === ''){
      alert("Please enter your address.")
      return
    }else if(values?.state_id === ''){
      alert("Please select your state.")
      return
    }else if(values?.city_id === ''){
      alert("Please select your city.")
      return
    }
    if(params?.id !== undefined){
      axios({
        method: 'post',
        url: site_ip + '/auth/setSiteUser',
        data: values,
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Status === 200) {
            alert("User details successfully updated.")
            navigate("/admin/site_user/list")
          } else {
            alert(response.data.Message)
          }
        })
        .catch(function (error) {
          alert("Email or Mobile number is already registered.")
        });
    }else{
      axios({
        method: 'post',
        url: site_ip + '/auth/addSiteUser',
        data: values,
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Status === 200) {
            alert("User details successfully added.")
            navigate("/admin/site_user/list")
          } else {
            alert(response.data.Message)
          }
        })
        .catch(function (error) {
          alert("Email or Mobile number is already registered.")
        });
    }
    
  };

  return (
    <div className="login-background">
      <Col style={{ display: "flex", alignItems: 'center' }}>
        <BackButton title={params?.id !== undefined ? "Update Doctor User" : "Add Doctor User"} />
      </Col>
      <Row style={{margin: 0, marginTop:"15px"}}>
        <Col md={12}>
          <div className='form-box' style={{backgroundColor:"#ffffff"}}>
            <Row style={{margin: 0}}>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>First Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="first_name" value={values.first_name} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Last Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="last_name" value={values.last_name} onChange={handleChange} />
                </Form.Group>
              </Col>
              {/* {<Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Degree <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="doc_final_degree" value={values.doc_final_degree} onChange={handleChange} />
                </Form.Group>
              </Col>} */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Email <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="user_email" value={values.user_email} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Mobile No. <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="user_mobile" value={values.user_mobile} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Specialty <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <select className='form-control' name="subject_id" onChange={handleChange} value={values.subject_id}>
                    <option value="">-Select-</option>
                    {speciality?.map((item, index)=>{
                      return(
                        <option key={item?.subject_id} value={item?.subject_id}>{item?.subject_name}</option>    
                      )
                    })}
                  </select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Institution <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <select className='form-control' name="institution_id" onChange={handleChange} value={values.institution_id}>
                    <option value="">-Select-</option>
                    {institution?.map((item, index)=>{
                      return(
                        <option key={item?.institution_id} value={item?.institution_id}>{item?.institute_name}</option>    
                      )
                    })}
                  </select>
                </Form.Group>
              </Col>
             
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Address <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control as="textarea" placeholder=""  name="address" value={values.address} onChange={handleChange}/>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>State <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <select className='form-control' name="state_id" onChange={handleChange} value={values.state_id}>
                    <option value="">-Select-</option>
                    {states?.map((item, index)=>{
                      return(
                        <option key={item?.state_id} value={item?.state_id}>{item?.state_name}</option>    
                      )
                    })}
                  </select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>City <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <select className='form-control' name="city_id" onChange={handleChange} value={values.city_id}>
                    <option value="">-Select-</option>
                    {cities?.map((item, index)=>{
                      return(
                        <option key={item?.city_id} value={item?.city_id}>{item?.city_name}</option>    
                      )
                    })}
                  </select>
                </Form.Group>
              </Col>
            </Row>
            <div style={{display:"flex", alignItems:'center', justifyContent:'center'}}>
              <Button variant="success" onClick={() => handleSubmit()}>{params?.id !== undefined ? "UPDATE USER" : "ADD USER"}</Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
