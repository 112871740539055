import React, { Component } from 'react';
import { Navbar, Nav, Image, NavDropdown } from 'react-bootstrap';
// import Pic1 from './img/Picture1.png';
// import centerImg from './img/centerPic.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
export default class Header extends Component {
  render() {
    const userdata = JSON.parse(localStorage.getItem('userdata'));

    return (
      <Navbar bg="white shadow" expand="lg">
        <div className="col-lg-4 col-8 order-1">
          <Navbar.Brand href="/Dashboard">
            {/* <Image src={Pic1} className="img-fluid" /> */}
          </Navbar.Brand>
        </div>
        <div className="col-lg-4 col-4 order-2 text-center d-lg-none">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <div className="col-lg-4 col-12 order-3 order-lg-2 text-center">
          <span className="mx-auto">
            {/* <Image src={centerImg} className="img-fluid center-pic" /> */}
          </span>
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="ml-auto order-lg-3">
          <Nav className="ml-auto">
            <NavDropdown
              title={
                userdata ? userdata.salutation + ' ' + userdata.first_name : ''
              }
              id="nav-dropdown"
              style={{ fontSize: '20px' }}
            >
              <Nav.Link href={'/Profile'} className="mr-3">
                {' '}
                My Profile
              </Nav.Link>
            </NavDropdown>
            <Nav.Link onClick={(e) => this.logout()}>
              <FontAwesomeIcon icon={faPowerOff} color={'#9c9c9c'} size="2x" />{' '}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
};
