import React from 'react';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import './topbar.css';
import Trip from "../../../image/trip_pro.png";
import KB from "../../../image/knowladgebridge.png";
import JB from "../../../image/jb_logo.png";
const TopBar = () => {
  const user_name = localStorage.getItem('user_name');
  // console.log(userdata)
  return (
    <div className="fix-header sticky">
      {/* <Container> */}
        <Row style={{ margin: "0px", padding:"0px 30px" }}>
          <Col style={{ padding: 0, display:'flex', alignItems:'center', height:"80px" }}>
            <img src={Trip} className="header-logo-img" />
          </Col>
          {/* <Col style={{ padding: 0, display:'flex', alignItems:'center', justifyContent:"center", height:"80px" }}>
            <img src={JB} className="header-logo-img" />
          </Col> */}
          <Col style={{ padding: 0, display:'flex', alignItems:'center', justifyContent:"flex-end", height:"80px" }}>
            <div style={{position:"relative"}}>
              <p style={{fontSize:"11px", marginBottom:"0px", position:"absolute", left:"50px"}}>Developed By</p>
              <img src={KB} className="header-logo-img" />
            </div>
          </Col>
        </Row>
      {/* </Container> */}
    </div>
  )
}

export default TopBar;
