import React,{useState,useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    Form,
    Modal,
  } from 'react-bootstrap';
  import axios from 'axios';
  import { site_ip } from '../../../globalSetting';
  import DataTable from 'react-data-table-component';
  import CustomTableStyle from '../customTableStyle';
  import { useNavigate } from 'react-router-dom';
  import BackButton from "../common/BackButton";
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import { CSVLink } from 'react-csv';
import { Label } from "reactstrap";

  export default function InstitutionDashboard(props){

    const [institutionDashboadData,setInstitutionDashboadData] = useState([]);
    const [institutions,setInstitutions] = useState([]);
    const [avgHighUsers,setAvgUsers] = useState([]);
    const [avgModUsers,setAvgModUsers]= useState([]);
    const [totalDoc,setTotalDoc] = useState(0);
    const [totalUsage,setTotalUsage] = useState(0)

        useEffect(()=>{
            getInstitutions();
        },[])
    

  const blankSearch = {
    date_from: '',
    date_to: '',
  }
  const [search, setSearch] = useState("");
  const [selectedInsitutionId,setSelectedInsitutionId]=useState(null);
  const getInstitutions=()=>{
    axios.get(site_ip+'/getInstitutions').then((res)=>{
      console.log(res.data.Data)
      setInstitutions(res.data.Data)
    })
  }

  const getInstitutionDashboard =()=>{

        axios.get(site_ip+`/getDashboard/${selectedInsitutionId}`).then((res)=>{
            console.log(res,'Dashboard data');
            let data=[];
            const avgModUsers=[];
            const avgHighUsers=[];
              
            setTotalDoc(res.data.TotalDoc);
            setTotalUsage(res.data.TotalUsage);

               if(res.data.AvgHighUsers){
                res.data.AvgHighUsers.map((item)=>{
                    let ele = {...item,"Average High User":item.AvgHighUsers}
                      avgHighUsers.push(ele);
                    data.push(ele);
                })
               } 

               if(res.data.AvgModUsers){
                res.data.AvgModUsers.map((item)=>{
                    let ele = {...item,"Average Mod User":item.AvgModUsers}
                    avgModUsers.push(ele);
                    data.push(ele);
                })
               }

            setInstitutionDashboadData(data)
            setAvgUsers(avgHighUsers);
            setAvgModUsers(avgModUsers);

        })
  }

  
  const handleInstitution =()=>{
    
    getInstitutionDashboard();
}

let tableHeader=[
    {
        name:'#',
        selector:(row,index)=>(index+1),
        width:"100px"
    },
    {
        name:"Name",
        selector:(row)=>(row.doctor),
        width:"200px"
    },
    {
        name:"Total Usage",
        selector:(row)=>(row.totalUsage),
        width:"200px"
    }
]


const handleChange = (e) => {
  const { name, value } = e.target;
  setSearch((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};


const pdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);

    const title = 'Institution Dashboard Data'; //QA Daily Report / Current date
    const headers = [
      [
        'Sr No.',
        'Name',
        'Total Usage',
      ],
    ];
    const data = institutionDashboadData.map((elt, index) => [
      index + 1,
      elt.UserType,
      elt.doctor,
      elt.totalUsage,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('institution_dashboard.pdf');
  };

    return (
        <>
         <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Institution Dashboard"} />
        </Col>
        <Col>
          <button
            onClick={pdf}
            style={{
              float: 'right',
              marginRight: '10px',
              backgroundcolor: 'blue',
            }}
            className="btn btn-primary"
            data={institutionDashboadData}
          >
            Download PDF
          </button>
          <CSVLink
            filename={'doctorwise_monthly_login_count.csv'}
            color="primary"
            style={{ float: 'right', marginRight: '10px' }}
            className="btn btn-primary"
            data={institutionDashboadData}
          >
            Download CSV
          </CSVLink>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Row> 
         
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Select Institution</Form.Label>
                          <Form.Control
                            className='customRegInput'
                            as="select"
                            name='institution_id'
                            value={institutions.institution_id}
                            onChange={(e)=>setSelectedInsitutionId(e.target.value)}
                            >
                              <option value="">-Select-</option>
                            {
                              institutions?.map((value,index)=>(

                                <option
                                  key={index}
                                  value={value.institution_id}
                                >{value.institute_name}</option>
                              ))
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>
                 
      </Row>
              </Row>
              
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={handleInstitution}
                >
                  Apply
                </button>
                <button className="close" onClick={() => { handleInstitution(blankSearch); setSearch(blankSearch) }}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
           
        {/* <Row> 
          <Col xl={12} className='mt-3' >
            <div className='bg-white'>
              <div className='admin-filter-box'>
                  <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Select Institution</Form.Label>
                          <Form.Control
                            className='customRegInput'
                            as="select"
                            name='institution_id'
                            value={institutions.institution_id}
                            onChange={handleInstitution}>
                              <option value="">-Select-</option>
                            {
                              institutions?.map((value,index)=>(

                                <option
                                  key={index}
                                  value={value.institution_id}
                                >{value.institute_name}</option>
                              ))
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>
                  </Row>
              </div>
            </div>
          </Col>
        </Row> */}


      <Row style={{marginBottom:"15px",display:"flex",flexDirection:"column", marginLeft:"15px"}}>
      
      {/* {!!totalDoc && totalUsage && <Label style={{background:"purple",borderRadius:"10px"}}>
          Total Doc :{totalDoc[0].totaldocs} ,Total Usages:{totalUsage[0].totalUsage}
        </Label>} */}


        {!!totalDoc && totalUsage && <>
          <Col style={{display:"flex", justifyContent:"space-evenly"}}>

          <div className="dashboard-card-box" style={{ width:"50%",background:"purple",borderRadius:"10px",margin:"10px"}}>
            <p className="dashboard-card-number" style={{color:"white"}}>{totalDoc[0].totaldocs}</p>
            <p className="dashboard-card-label" style={{color:"white"}}>Total Doc</p>
          </div>
          <div className="dashboard-card-box" style={{width:"50%",background:"#26B283",borderRadius:"10px",margin:"10px"}}>
            <p className="dashboard-card-number" style={{color:"white"}}>{totalUsage[0].totalUsage}</p>
            <p className="dashboard-card-label" style={{color:"white"}}>Total Usage</p>
          </div>
          </Col>
         
        </> }

        <Label>
          Average High Users
        </Label>
      
        
        <Col>
            <DataTable 
                columns={tableHeader}
                data={avgHighUsers}
                fixedHeader
                pagination
                customStyles={CustomTableStyle}
                striped
                highlightOnHover
                display
                
            />
        </Col>       
      </Row> 

      <Row style={{marginBottom:"15px",display:"flex",flexDirection:"column", marginLeft:"15px"}}>
        <Label>
          Average Moderate Users
        </Label>
        
        <Col>
            <DataTable 
                columns={tableHeader}
                data={avgModUsers}
                fixedHeader
                pagination
                customStyles={CustomTableStyle}
                striped
                highlightOnHover
            />
        </Col>       
      </Row> 
    </>
    )

  }