import React, { useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import '../style.css';
import { site_ip } from '../../globalSetting';
import axios from 'axios';

const RedeemAccess = (props) => {
  
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);  
  const [accesscode, setAccessCode] = useState('')

  const verifyAccessCode = () =>{
    axios({
      method: 'get',
      url: `${site_ip}/getPromocodeById/`+accesscode,
      dataType: 'json',
    })
      .then(function (response) {
        props.redemptionSuccess(response.data.Data[0].coupon_code, response.data.Data[0].institution_id)
        // props.history.push('/register', {token: true, accesscode:accesscode})
      })
      .catch(function (error) {
        setError(true);
      });
  }


  return (
    <>
      <Modal size={"sm"} show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>
            <p style={{fontSize:"24px", color:"#543767", marginBottom:"0px", fontWeight:"bold"}}>Redeem Access</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            {error ? 
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              <Alert.Heading><p style={{fontSize:"16px", marginBottom:"0px"}}>Invalid Access Code.</p></Alert.Heading>
            </Alert> : null }
            {success ? 
            <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
              <Alert.Heading><p style={{fontSize:"16px", marginBottom:"0px"}}>Thank you very much for showing interest in TRIP. We will soon get back to you.</p></Alert.Heading>
            </Alert> : null }
            <p style={{fontSize:"14px", color:"#000000", fontWeight:"bold"}}>Access Token</p>
          </Col>
        </Row>  
        <Row>
            <Col>
              <Form.Group
                className="mb-3 col-xs-12 "
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Enter Access Token"
                  name="name"
                  value={accesscode}
                  onChange={(e) => {setAccessCode(e.target.value); setError(false)}}
                />
              </Form.Group>
            </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => verifyAccessCode()}>
            Verify
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RedeemAccess
