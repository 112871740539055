import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../customTableStyle';
import { useNavigate } from 'react-router-dom';
import BackButton from "../common/BackButton";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';

export default function ResumeList(props) {

  const navigate = useNavigate();
  const blankSearch = {
    first_name: "",
    user_email: "",
    user_login: "",
    first_time_login_ind: "",
    mobile_number: '',
    institution_id: '',
  }
  const [search, setSearch] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [mystates, setMyStates] = useState([]);
  const [mycities, setCities] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // setFirstNameError(false);
    // setEmailError(false);
    // setMobileError(false);
    // setStateError(false);
    // setCityError(false);
    // setPincodeError(false);

    if (name === 'state_id') {
      fetchCities(value)
    }
  };

  const [optionPopup, setOptionPopup] = useState(false);
  const [userList, setUserList] = useState([]);
  const getUserList = (req) => {
    axios
      .post(site_ip + '/auth/searchSiteUsers', req)
      .then((res) => {
        // console.log(res);
        setUserList(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserList(blankSearch);
    fetchStates();
    fetchSubjects();
    fetchInstitutions();
  }, []);

  const fetchStates = (e) => {
    fetch(`${site_ip}/getStatesByCountry/1`)
      .then((res) => res.json())
      .then((result) => {
        setMyStates(result.Content)
      });
  }

  const fetchCities = (id) => {
    fetch(`${site_ip}/getCities/` + id)
      .then((res) => res.json())
      .then((result) => {
        setCities(result.Content)
      });
  }

  const [subjects, setSubjects] = useState([])
  const fetchSubjects = (id) => {
    fetch(`${site_ip}/getSubjects`)
      .then((res) => res.json())
      .then((result) => {
        setSubjects(result.Content)
      });
  }

  const [institutions, setInstitutions] = useState([])
  const fetchInstitutions = (id) => {
    fetch(`${site_ip}/getInstitutions`)
      .then((res) => res.json())
      .then((result) => {
        setInstitutions(result.Data)
      });
  }

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    // {
    //   name: 'Coupon Code',
    //   selector: (row) => row?.coupon_code,
    // },
    // {
    //   name: 'Coupon Redeem Date',
    //   selector: (row) => row?.coupon_redeem_date,
    // },
    // {
    //   name: 'Salutation',
    //   selector: (row) => row?.salutation,
    // },
    {
      name: 'First Name',
      selector: (row) => row?.first_name,
    },
    {
      name: 'Last Name',
      selector: (row) => row?.last_name,
    },
    {
      name: 'Email',
      selector: (row) => row?.user_email,
    },
    {
      name: 'Mobile',
      selector: (row) => row?.user_mobile,
    },
    {
      name: 'State',
      selector: (row) => row?.state_name,
    },
    {
      name: 'Action',
      width: "100px",
      cell: (row) => (
        <>
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <button style={{ padding: "5px" }} onClick={() => navigate("/admin/site_user/update/" + row.user_id)} className="primary-plus-button">
              <i className="fas fa-pencil-alt" style={{ color: "#ffffff" }}></i>
            </button>
          </div>
          <div style={{ paddingTop: "5px", paddingBottom: "5px", marginLeft: "5px" }}>
            <button style={{ padding: "5px" }} onClickCapture={() => setConfirmation(row.user_id)} className="secondary-plus-button">
              <i className="far fa-trash-alt" style={{ color: "#ffffff" }}></i>
            </button>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ]

  const deleteRow = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/deleteSiteUser',
      data: {
        user_id: confirmation
      },
      dataType: 'json',
    })
      .then(function (response) {
        setConfirmation(null)
        getUserList(search)
      })
      .catch(function (error) {
        alert("Email or Mobile number is already registered.")
      });
  }


  const pdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);

    const title = 'Coupon Usage Report'; //QA Daily Report / Current date
    const headers = [
      [
        'Sr No.',
        'Coupon Code',
        'Redeem Date',
        'Saluation',
        'First Name',
        'Last Name',
        'Email',
        'Mobile',
        'State',
      ],
    ];
    const data = userList.map((elt, index) => [
      index + 1,
      elt.coupon_code,
      elt.coupon_redeem_date,
      elt.salutation,
      elt.first_name,
      elt.last_name,
      elt.user_email,
      elt.user_mobile,
      elt.state_name
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('CouponUsageReport.pdf');
  };

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Doctor User List"} />
        </Col>
        
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={search.first_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Doctor Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="user_email"
                      value={search.user_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobile_number"
                      value={search.mobile_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Institution</Form.Label>
                    <Form.Control
                      className="customRegisterInput"
                      as="select"
                      name="institution_id"
                      value={search.institution_id}
                      onChange={handleChange}

                    >
                      <option value="">-Select-</option>
                      {institutions?.map((value, index) => (
                        <option key={index} value={value.institution_id}>{value.institute_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={() => getUserList(search)}
                >
                  Apply
                </button>
                <button className="close" onClick={() => { getUserList(blankSearch); setSearch(blankSearch) }}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Modal show={confirmation === null ? false : true} onHide={() => setConfirmation(null)} animation={false}>
          <Modal.Header>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmation(null)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => deleteRow()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      <Row>
        <Col>
          <DataTable columns={tableHeader} data={userList} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
    </>
  );
}
