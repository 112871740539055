import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import AuthHeader from './content/common/AuthHeader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './afterlogin.css';
import './content/homepage.css';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Signup from './content/Signup';
import Dashboard from './content/Dashboard';

import CouponUsageReport from './content/Reports/CouponUsageReport';
import Top10Articles from './content/Reports/Top10Articles';
import UnderDevelopment from './content/UnderDevelopment';
import LoginCount from './content/Reports/LoginCount';
import AlembicRegistration from './content/Reports/AlembicRegData';
import JBCLRegistration from './content/Reports/JBCLRegData';
import InstitutionDashboard from './content/Reports/InstitutionDashboard';

import SiteUserList from './content/SearchUser/List';
import SiteUserAdd from './content/SearchUser/Add';
import VisitsByMonth from './content/Reports/VisitsByMonth';
import UpdateDoctorsPassword from './content/Reports/UpdateDoctorsPassword';
import UploadData from './content/Reports/UploadData';

 

function App() {

  const LayoutOfLoginAndSignup = (children) => (
    <>
      {/* <AuthHeader />  */}
      {children}
    </>
  );

  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <div className="theme-background">
        <LeftSideBar />
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 80px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
  );

  const LayoutOfAfterLoginOnlyHeader = (children) => (
    <>
      <TopBar />
      <div className="theme-background">
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 80px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={LayoutOfLoginAndSignup(<Login />)}
        />{' '}
        <Route
          exact
          path="/signup"
          layout={LayoutOfLoginAndSignup}
          element={<Signup />}
        />{' '}
        <Route
          exact
          path="/dashboard"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Dashboard />)}
        />
        <Route
          exact
          path="/reports/coupon_usage_report"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<CouponUsageReport />)}
        />
        <Route
          exact
          path="/reports/top_accessed_articles"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Top10Articles />)}
        />
        <Route
          exact
          path="/reports/login_count"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<LoginCount />)}
        />
        <Route
          exact
          path="/reports/alembic_registration"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<AlembicRegistration />)}
        />
        <Route
          exact
          path='/reports/dashboard'
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<InstitutionDashboard/>)}
        />
        <Route
        exact
        path='/reports/visitsbymonth'
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<VisitsByMonth/>)}
        />
        <Route
          exact
          path="/reports/jbcl_registration"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<JBCLRegistration />)}
        />
        <Route
          exact
          path="/site_user/list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<SiteUserList />)}
        />
        <Route
          exact
          path="/site_user/add"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<SiteUserAdd />)}
        />
        <Route
          exact
          path="/site_user/update/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<SiteUserAdd />)}
        />
        <Route
          exact
          path="/reports/under_development"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<UnderDevelopment />)}
        />

        <Route
          exact
          path="/site_user/updatedoctorspassword"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<UpdateDoctorsPassword />)}
        />
        
        <Route
          exact
          path="/site_user/uploaddata"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<UploadData />)}
        />
        
      </Routes>
      
    </>
  );
}

export default App;
