import React, { useState, useEffect } from 'react';
import { site_ip } from '../globalSetting';
import { Row, Col, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './common.css';
import axios from 'axios';
function Dashboard() {
  const [usageNos, setUsageNos] = useState(0);
  const [couponNos, setCouponNos] = useState(0);
  const [redeemedNos, setRedeemedNos] = useState(0);
  const [isAdmin,setIsAdmin]= useState(false);
  const [totalDoc,setTotalDoc] = useState(0);
  const [totalUsage,setTotalUsage] = useState(0)
  
  useEffect(() => {
    checkUser();
    getDashboard();
  }, [])

  const getDashboard = () => {

    if(!isAdmin){
      let selectedInsitutionId = localStorage.getItem("institution_id");

      axios.get(site_ip+`/getDashboard/${selectedInsitutionId}`).then((res)=>{
        console.log(res,'Dashboard data');
          
        setTotalDoc(res.data.TotalDoc[0].totaldocs);
        setTotalUsage(res.data.TotalUsage[0].totalUsage);
      });
    }
   

    axios
      .get(site_ip + '/adminDashboardStats')
      .then((res) => {
        if (res?.data?.Coupon_redeemed !== undefined && res?.data?.Coupon_redeemed?.length > 0) {
          setRedeemedNos(res?.data?.Coupon_redeemed[0]?.coupon_used)
        }
        if (res?.data?.Coupons !== undefined && res?.data?.Coupons?.length > 0) {
          setCouponNos(res?.data?.Coupons[0]?.coupon)
        }
        if (res?.data?.Usage !== undefined && res?.data?.Usage?.length > 0) {
          setUsageNos(res?.data?.Usage[0]?.usage_count)
        }
        // console.log(res.data)
        // setDashboardData(res.data)
      }).catch((err) => console.log(err));
  }

  
  const checkUser=()=>{
    let type = localStorage.getItem('institution_id');
    console.log(type)
    if(type !=="null"){
      setIsAdmin(false)
      console.log(type)

    }else{
      setIsAdmin(true)
      console.log(type)

    }
  }

  return (
    <div>
       { isAdmin &&
         <Row>
        <Col>
          <div className="dashboard-card-box" style={{background:"purple",borderRadius:"10px"}}>
            <p className="dashboard-card-number" style={{color:"white"}}>{couponNos}</p>
            <p className="dashboard-card-label" style={{color:"white"}}>Total Coupon Issued</p>
          </div>
          </Col>
          <Col>
            <div className="dashboard-card-box" style={{background:"#26B283",borderRadius:"10px"}}>
              <p className="dashboard-card-number" style={{color:"white"}}>{redeemedNos}</p>
              <p className="dashboard-card-label" style={{color:"white"}}>Total Coupon Redeemed</p>
            </div>
          </Col>
        <Col>
          <div className="dashboard-card-box" style={{background:"#F13A5E",borderRadius:"10px"}}>
            <p className="dashboard-card-number" style={{color:"white"}}>{usageNos}</p>
            <p className="dashboard-card-label" style={{color:"white"}}>Total Usage</p>
          </div>
        </Col>
      </Row>
      }
      { !isAdmin &&  <Row>
        <Col>
          <div className="dashboard-card-box" style={{background:"purple",borderRadius:"10px"}}>
            <p className="dashboard-card-number" style={{color:"white"}}>{totalDoc}</p>
            <p className="dashboard-card-label" style={{color:"white"}}>Total Doctors data uploaded</p>
          </div>
          </Col>
        <Col>
          <div className="dashboard-card-box" style={{background:"#F13A5E",borderRadius:"10px"}}>
            <p className="dashboard-card-number" style={{color:"white"}}>{totalUsage}</p>
            <p className="dashboard-card-label" style={{color:"white"}}>Total Usage</p>
          </div>
        </Col>
      </Row>}
     
    </div>
  );
}
export default Dashboard;
