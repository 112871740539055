import React, { useState } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import BackButton from "../common/BackButton";

export default function UpdateDoctorsPassword() {
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = () => {
    // Validate the form before making the API request
    if (email.trim() === '') {
      alert('Please enter your email');
      return;
    }

    if (oldPassword.trim() === '') {
      alert('Please enter your old password');
      return;
    }

    if (confirmPassword.trim() === '') {
      alert('Please enter your confirm password');
      return;
    }

    if (oldPassword !== confirmPassword) {
      alert('Old password and confirm password do not match');
      return;
    }

    axios.post(`${site_ip}/auth/updatePass`, {
      user_email:email,
      password:oldPassword,
    })
    .then(response => {
      console.log(response.data);

      if(response.data?.Success==200){
        alert('Password changed!')
      }
      setEmail('');
      setOldPassword('');
      setConfirmPassword('');
    })
    .catch(error => {
      console.error(error);
      alert('An error occurred. Please try again.');
    });
  };

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Visits by month"} />
        </Col>
      </Row>

      <Row>
        <Col xl={4} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
            <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="old_password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirm_password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
           

              <div className="btn-box  border-top mt-3 pt-3" style={{ display: "flex", justifyContent:"center" }}>
                <button
                  className="success"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
