import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../customTableStyle';
import { useNavigate } from 'react-router-dom';
import BackButton from "../common/BackButton";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';


export default function LoginCount(props) {

  
  const months = 
    {1:"January", 
    2:"Februrary",
    3:"March",
    4:"April",
    5:"May",
    6:"June",
    7:"July",
    8:"August",
    9:"September",
    10:"October",
    11:"November",
    12:"December"}
  

  const blankSearch = {
    date_from: '',
    date_to: '',
    // institution_id:localStorage.getItem('institution_id')
  }
  const [search, setSearch] = useState(blankSearch);
  const [countReport, setCountReport] = useState([]);

const [isAdmin,setIsAdmin]= useState(false);
const [institutions,setInstitutions] = useState([]);

const [selectedInsitutionId,setSelectedInsitutionId]=useState(null);

const checkUser=()=>{
  let type = localStorage.getItem('institution_id');
  console.log(type)
  if(type !=="null"){
    setIsAdmin(false)
    setSelectedInsitutionId(type);
    console.log(type)

  }else{
    setIsAdmin(true)
    console.log(type)

  }
}


const getInstitutions=()=>{
  axios.get(site_ip+'/getInstitutions').then((res)=>{
    console.log(res.data.Data)
    setInstitutions(res.data.Data)
  })
}
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getCountReport = (req) => {
     req ={...req,institution_id:selectedInsitutionId}
    axios
      .post(site_ip + '/LoginCount', req)
      .then((res) => {
        // console.log(res);
        let data = [];
        res.data.Data?.map((item)=>{
          let ele = {...item, loginmonth: months[item?.loginmonth]}
          data.push(ele)
        })
        setCountReport(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    checkUser();
    getInstitutions();
    // getCountReport(blankSearch);
  }, []);


  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Doctor Name',
      selector: (row) => row?.doctor,
    },
	{
      name: 'Email',
      selector: (row) => row?.user_email,
    },
    {
      name: 'Count',
      selector: (row) => row?.logincount,
    },
    {
      name: 'Login Month',
      selector: (row) => row?.loginmonth,
    },
    {
      name: 'Login Year',
      selector: (row) => row?.loginyear,
    },
  ]


  const pdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);

    const title = 'Doctorwise Monthly Login Count'; //QA Daily Report / Current date
    const headers = [
      [
        'Sr No.',
        'Doctor Name',
		'Email',
        'Count',
        'Login Month',
        'Login Year',
      ],
    ];
    const data = countReport.map((elt, index) => [
      index + 1,
      elt.doctor,
	  elt.user_email,
      elt.logincount,
      elt?.loginmonth,
      elt.loginyear,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('doctorwise_monthly_login_count.pdf');
  };

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Doctorwise - Monthly login Count"} />
        </Col>
        <Col>
          <button
            onClick={pdf}
            style={{
              float: 'right',
              marginRight: '10px',
              backgroundcolor: 'blue',
            }}
            className="btn btn-primary"
            data={countReport}
          >
            Download PDF
          </button>
          <CSVLink
            filename={'doctorwise_monthly_login_count.csv'}
            color="primary"
            style={{ float: 'right', marginRight: '10px' }}
            className="btn btn-primary"
            data={countReport}
          >
            Download CSV
          </CSVLink>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {isAdmin &&  <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Select Institution</Form.Label>
                          <Form.Control
                            className='customRegInput'
                            as="select"
                            name='institution_id'
                            value={institutions.institution_id}
                            onChange={(e)=>setSelectedInsitutionId(e.target.value)}
                            >
                              <option value="">-Select-</option>
                            {
                              institutions?.map((value,index)=>(

                                <option
                                  key={index}
                                  value={value.institution_id}
                                >{value.institute_name}</option>
                              ))
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>}
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={() => getCountReport(search)}
                >
                  Apply
                </button>
                <button className="close" onClick={() => { getCountReport(blankSearch); setSearch(blankSearch) }}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={tableHeader} data={countReport} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
    </>
  );
}
