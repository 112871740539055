import React,{useState,useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    Form,
    Modal,
  } from 'react-bootstrap';
  import axios from 'axios';
  import { site_ip } from '../../../globalSetting';
  import DataTable from 'react-data-table-component';
  import CustomTableStyle from '../customTableStyle';
  import { useNavigate } from 'react-router-dom';
  import BackButton from "../common/BackButton";
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import { CSVLink } from 'react-csv';
import { Label } from "reactstrap";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


export default function VisitsByMonth(props){

    const [institutions,setInstitutions] = useState([]);

    const [visits,setVisits]= useState([]);
    const [isAdmin,setIsAdmin]= useState(false);


    useEffect(()=>{
        checkUser();
        getInstitutions();

    },[])

    
  const blankSearch = {
    date_from: '',
    date_to: '',
  }
  const [search, setSearch] = useState("");
  const [selectedInsitutionId,setSelectedInsitutionId]=useState(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getInstitutions=()=>{
    axios.get(site_ip+'/getInstitutions').then((res)=>{
      console.log(res.data.Data)
      setInstitutions(res.data.Data)
    })
  }

    const getVisitData =(req)=>{
        console.log(req,'REQ')
        axios.post(site_ip+`/visitsByMonth`,{
          institution_id:selectedInsitutionId,
          date_from:req.date_from,
          date_to:req.date_to
        }).then((res)=>{
            let data=[];

            console.log(res,'Visits');
            setVisits(res.data.Data);
        })
    }


  const handleInstitution =(req)=>{

        getVisitData(req);
}



const pdf = () => {
  const unit = 'pt';
  const size = 'A4';
  const orientation = 'landscape';
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(11);

  const title = 'Institution Dashboard Data'; //QA Daily Report / Current date
  const headers = [
    [
      'Sr No.',
      'Name',
      'Total Usage',
    ],
  ];
  const data = visits.map((elt, index) => [
    index + 1,
    elt.UserType,
    elt.doctor,
    elt.totalUsage,
  ]);
  let content = {
    startY: 50,
    head: headers,
    body: data,
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save('vistsby_month.pdf');
};


let tableHeader =[
  {
    name:'#',
    selector:(row,index)=>(index+1),
    width:"100px"
  },
  {
    name:"Name",
    selector:(row)=>(row.doctor),
    width:"200px"
  },
  {
    name:"Login Month",
    selector:(row)=>(row.login_month),
    width:"200px"
  },
  {
    name:"Login Year",
    selector:(row)=>(row.login_year),
    width:"200px"
  },
  {
    name:"Total Logins",
    selector:(row)=>(row.total_logins),
    width:"200px"
  }
]


const checkUser=()=>{
  let type = localStorage.getItem('institution_id');
  console.log(type)
  if(type !=="null"){
    setIsAdmin(false)
    setSelectedInsitutionId(type);
    console.log(type)

  }else{
    setIsAdmin(true)
    console.log(type)

  }
}


return (<>
     <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Visits by month"} />
        </Col>
        <Col>
          <button
            onClick={pdf}
            style={{
              float: 'right',
              marginRight: '10px',
              backgroundcolor: 'blue',
            }}
            className="btn btn-primary"
            data={visits}
          >
            Download PDF
          </button>
          <CSVLink
            filename={'doctorwise_monthly_login_count.csv'}
            color="primary"
            style={{ float: 'right', marginRight: '10px' }}
            className="btn btn-primary"
            data={visits}
          >
            Download CSV
          </CSVLink>
        </Col>
    </Row>
    <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="month"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="month"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Row> {isAdmin &&  <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Select Institution</Form.Label>
                          <Form.Control
                            className='customRegInput'
                            as="select"
                            name='institution_id'
                            value={institutions.institution_id}
                            onChange={(e)=>setSelectedInsitutionId(e.target.value)}
                            >
                              <option value="">-Select-</option>
                            {
                              institutions?.map((value,index)=>(

                                <option
                                  key={index}
                                  value={value.institution_id}
                                >{value.institute_name}</option>
                              ))
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>}
                  </Row>
              </Row>
              
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={() => handleInstitution(search)}
                >
                  Apply
                </button>
                <button className="close" onClick={() => { handleInstitution(blankSearch); setSearch(blankSearch) }}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

        {visits.length>0 &&  <ResponsiveContainer width="100%" height="50%">
        <AreaChart
          width={500}
          height={400}
          data={visits}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="login_month" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="total_logins" stackId="1" stroke="#8884d8" fill="#8884d8" />
          {/* <Area type="monotone" dataKey="login_year" stackId="1" stroke="#82ca9d" fill="#82ca9d" /> */}
          {/* <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
        </AreaChart>
      </ResponsiveContainer>}
      <Row>
        <Col>
            <DataTable 
                columns={tableHeader}
                data={visits}
                fixedHeader
                pagination
                customStyles={CustomTableStyle}
            />
        </Col>       
      </Row> 
                          
      
</>)

}