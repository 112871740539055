import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar, Nav, Image, NavDropdown } from 'react-bootstrap';
import Home from './components/Home';
import VideoPage from './components/VideoPage';
import Dashboard from './components/Dashboard';
import OTPlogin from './components/Otplogin';
import ForgotPassword from './components/Forgot_Password';
import ResetPassword from './components/ResetPassword'
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { site_ip } from './globalSetting';
import LandingPage from './components/LandingPage';
 
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.logout(), 600000);
  }

  logout() {
    window.location.href = '/';
    let loginid = JSON.parse(localStorage.getItem('loginid'));
    axios({
      method: 'post',
      url: site_ip + '/setUserLogout',
      data: {
        login_id: loginid,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data) {
          localStorage.removeItem('userdata');
          localStorage.removeItem('loginid');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  }

  render() {
    return (
      <Routes>
        <Route
          exact
          path="/"
          element={<Home />}
        />
        <Route
          exact
          path="/login"
          element={<Home />}
        />
        <Route
          exact
          path="/know-about-trip/videos"
          element={<VideoPage />}
        />
        <Route
          exact
          path="/Dashboard"
          element={<Dashboard />}
        />
        <Route
          exact
          path="/forgot_password"
          element={<ForgotPassword />}
        />
        <Route
            exact
            path="/updatepass/:id"
            element={<ResetPassword />}
        />
        <Route
          exact
          path="/Otplogin"
          element={<OTPlogin />}
        />
      </Routes>
    );
  }
}

export default App;
