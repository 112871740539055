import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import '../style.css';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
const { detect } = require('detect-browser');
const browser = detect();

const BasicInformation = (props) => {
  const navigate = useNavigate();
  // activeAccessCode
  const [mystates, setMyStates] = useState([]);
  const [mycities, setCities] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);  

  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [pincodeError, setPincodeError] = useState(false);
  const [specialityError, setSpecialityError] = useState(false);
  const fetchStates = (e) => {
    fetch(`${site_ip}/getStatesByCountry/1`)
      .then((res) => res.json())  
      .then((result) => {
        setMyStates(result.Content)
      });
  }

  useEffect(()=>{
    fetchStates();
    fetchSubjects();
  }, [])

  const fetchCities = (id) => {
    fetch(`${site_ip}/getCities/`+id)
      .then((res) => res.json())  
      .then((result) => {
        setCities(result.Content)
      });
  }

  const [subjects, setSubjects] = useState([])
  const fetchSubjects = (id) => {
    fetch(`${site_ip}/getSubjects`)
      .then((res) => res.json())  
      .then((result) => {
        setSubjects(result.Content)
      });
  }

  const initialValues = {
    salutation: "Dr.",
    first_name: "",
    last_name: "",
    user_email: "",
    user_mobile: "",
    city_id: "",
    state_id: "",
    country_id: 1,
    zipcode: "",
    gender: "", 
    publisher_id: "",
    institution_id: props.institutionId,
    password: "",
    subject_id:"",
    coupon_code:props.activeAccessCode
  };
  const [values, setValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFirstNameError(false);
    setEmailError(false);
    setMobileError(false);
    setStateError(false);
    setCityError(false);
    setPincodeError(false);

    if(name === 'state_id'){
      fetchCities(value)
    }
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const pincode = (mobile) => {
    var phoneno = /^\d{6}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const submitForm = () => {
    if(values.first_name === ''){
      setFirstNameError("Please enter name");
      return
    }else if(!validateEmail(values.user_email)){
      setEmailError("Invalid Email address")
      return
    }else if(!phonenumber(values.user_mobile)){
      setMobileError("Invalid Mobile Number")
      return
    }else if(values.subject_id === ''){
      setSpecialityError("Please select your speciality.")
      return
    }else if(values.state_id === ''){
      setStateError("Please select your state.")
      return
    }else if(values.city_id === ''){
      setCityError("Please select your city")
      return
    }else if(!pincode(values.zipcode)){
      setPincodeError("Invalid pincode")
      return
    }else{
      values.coupon_code = props.activeAccessCode;
      values.institution_id = props.institutionId;
      axios({
        method: 'post',
        url: `${site_ip}/auth/signup`,
        data: values,
        dataType: 'json',
      })
        .then(function (response) {
          setValues(initialValues);
          setSuccess(true);
          setTimeout(()=>{
            props.handleClose();
          }, 4000)
          window.location.href = 'https://pages.razorpay.com/pl_KpOaInWRHKaj83/view';
        })
        .catch(function (error) {
          alert("Something went wrong. Please try again.");
        });
    }
  };

  return (
    <>
      <Modal size={"md"} show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>
            <p style={{fontSize:"24px", color:"#543767", marginBottom:"0px", fontWeight:"bold"}}>Basic Information</p>
            <p style={{fontSize:"14px", color:"#543767", marginBottom:"0px"}}>(One year TRIP Pro Subscription Price USD $55)</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            {error ? 
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              <Alert.Heading><p style={{fontSize:"16px", marginBottom:"0px"}}>Error! All fields are required.</p></Alert.Heading>
            </Alert> : null }
            {success ? 
            <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
              <Alert.Heading><p style={{fontSize:"16px", marginBottom:"0px"}}>Congratulations! Your account has been created successfully. Please check your email for account details.</p></Alert.Heading>
            </Alert> : null }
            {/* <p style={{fontSize:"14px", color:"#000000", fontWeight:"bold"}}>Personal Details</p> */}
          </Col>
        </Row>  
        <Row>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-6"
              >
                <Form.Control
                  className="customRegisterInput"
                  as="select"
                  name="salutation"
                  value={values.salutation}
                  onChange={handleChange}
                  
                >
                  <option value="Dr.">Dr.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs">Mrs</option>
                </Form.Control>
                
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-6"
              ></Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-6"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="First Name *"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  style={{border: !firstNameError ? "1px solid #ccc" : "1px solid #ff0000"}}
                />
                {firstNameError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{firstNameError}</span> : null }
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12 "
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Last Name *"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="email"
                  placeholder="Email *"
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                  style={{border: !emailError ? "1px solid #ccc" : "1px solid #ff0000"}}
                />
                {emailError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{emailError}</span> : null }
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="tel"
                  placeholder="Mobile Number *"
                  name="user_mobile"
                  maxLength={10}
                  value={values.user_mobile}
                  onChange={handleChange}
                  style={{border: !mobileError ? "1px solid #ccc" : "1px solid #ff0000"}}
                />
                {mobileError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{mobileError}</span> : null }
              </Form.Group>
        </Row>
        <Row>
          <Col><p style={{fontSize:"14px", color:"#000000", fontWeight:"bold"}}>Other Details</p></Col>
        </Row>
        <Row>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  as="select"
                  name="subject_id"
                  value={values.subject_id}
                  onChange={handleChange}

                >
                  <option value="">Select Speciality *</option>
                  {subjects?.map((value, index) => (
                    <option key={index} value={value.subject_id}>{value.subject_name}</option>
                  ))}
                </Form.Control>
                {specialityError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{specialityError}</span> : null }
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  as="select"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                  style={{border: !stateError ? "1px solid #ccc" : "1px solid #ff0000"}}
                >
                  <option value="">Select State *</option>
                  {mystates?.map((value, index) => (
                    <option key={index} value={value.state_id}>{value.state_name}</option>
                  ))}
                </Form.Control>
                {stateError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{stateError}</span> : null }
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  as="select"
                  name="city_id"
                  value={values.city_id}
                  onChange={handleChange}
                  style={{border: !cityError ? "1px solid #ccc" : "1px solid #ff0000"}}
                >
                  <option value="">Select City *</option>
                  {mycities?.map((value, index) => (
                    <option key={index} value={value.city_id}>{value.city_name}</option>
                  ))}
                </Form.Control>
                {cityError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{cityError}</span> : null }
              </Form.Group>
              <Form.Group
                className="mb-3 col-sm-6 col-xs-12"
              >
                <Form.Control
                  className="customRegisterInput"
                  type="text"
                  placeholder="Pin code *"
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                  style={{border: !pincodeError ? "1px solid #ccc" : "1px solid #ff0000"}}
                />
                {pincodeError ? <span style={{color:"#ff0000", fontSize:"10px"}}>{pincodeError}</span> : null }
              </Form.Group>
            </Row>
            <Row>
              {/* <Col><p style={{fontSize:"10px", color:"#ff0000", fontWeight:"bold"}}>* All fields are mandatory</p></Col> */}
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => submitForm()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BasicInformation;
