import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Branding from '../image/Trip_Branding_Page.jpg';
import BrandingMobile from '../image/Mobile_page425_trip.jpg';
import './style.css';
import moment from "moment";
export default function Dashboard() {

  const openURL = () =>{
    window.open('https://gateway.webofdiscovery.com/', '_blank');
  }
  const[currentUser, setCurrentUser] = useState([])

  useEffect(()=>{
    setCurrentUser(JSON.parse(localStorage.getItem("userdata")))
  }, [])

  return (
    <div>
      {/* <h4>Trip Dashboard</h4>
      <Link to="/">Logout</Link> */}
      <div style={{top:"10px", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", width:"100%"}}>
        <h5>
          Welcome
          {" " +
            currentUser.salutation +
            " " +
            currentUser.first_name +
            " " +
            currentUser.last_name}
        </h5>
        <p>
          <span> Last Login </span>
          <span>
            <small className="text-dark h6">
              {moment(currentUser.last_login_date).format("DD-MM-YYYY") +
                " "}
            </small>
            <small className="text-dark h6">
              {moment(currentUser.last_login_date).format("hh:mm:ss")}
            </small>
          </span>
        </p>
      </div>
      <img 
        src={localStorage.getItem('mobileImg') !== "" && localStorage.getItem('mobileImg') !== null ? localStorage.getItem('mobileImg') : BrandingMobile}
        style={{width:"100%"}}
        className="forSmScreenOnly"
      />
      <img src={localStorage.getItem('desktopImg') !== "" && localStorage.getItem('desktopImg') !== null ? localStorage.getItem('desktopImg') : Branding} style={{width:"100%"}} className="forBgScreenOnly"/>
      <div className="spaceOnlyDiv"/>
      <div style={{display:"flex", alignItems:"center", justifyContent:'center', position:"fixed", width:"100%", bottom:"15px" }}>
          <button
              style={{ marginLeft: "10px", height:"80px", width:"200px", marginTop:"-80px" }}
              className="btn btn-primary btn-lg"
              onClick={() => openURL()}
            >
              Enter Content
            </button>
      </div>
    </div>
  );
}
