import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../customTableStyle';
import { useNavigate } from 'react-router-dom';
import BackButton from "../common/BackButton";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';

export default function LoginCount(props) {

  const months = 
    {1:"January",
    2:"Februrary",
    3:"March",
    4:"April",
    5:"May",
    6:"June",
    7:"July",
    8:"August",
    9:"September",
    10:"October",
    11:"November",
    12:"December"}
  

  const blankSearch = {
    date_from: '',
    date_to: '',
  }
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [countReport, setCountReport] = useState([]);
  const getCountReport = () => {
    axios
      .get(site_ip + '/getJBCLRegData')
      .then((res) => {
        // console.log(res);
        let data = [];
        res.data.Data?.map((item)=>{
          let ele = {...item, loginmonth: months[item?.loginmonth]}
          data.push(ele)
        })
        setCountReport(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCountReport(blankSearch);
  }, []);


  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'First Name',
      selector: (row) => row?.first_name,
    },
    {
      name: 'Last Name',
      selector: (row) => row?.last_name,
    },
    {
      name: 'Email',
      selector: (row) => row?.user_email,
    },
    {
      name: 'Mobile',
      selector: (row) => row?.user_mobile,
    },
    {
      name: 'State',
      selector: (row) => row?.state_name,
    },
  ]


  const pdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);

    const title = 'JBCL Registration Data'; //QA Daily Report / Current date
    const headers = [
      [
        'Sr No.',
        'First Name',
        'Last Name',
        'Email',
        'Mobile',
        'State',
      ],
    ];
    const data = countReport.map((elt, index) => [
      index + 1,
      elt.first_name,
      elt.last_name,
      elt.user_email,
      elt?.user_mobile,
      elt.state_name,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('JBCL_registration.pdf');
  };

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"JBCL Registration Data"} />
        </Col>
        <Col>
          <button
            onClick={pdf}
            style={{
              float: 'right',
              marginRight: '10px',
              backgroundcolor: 'blue',
            }}
            className="btn btn-primary"
            data={countReport}
          >
            Download PDF
          </button>
          <CSVLink
            filename={'doctorwise_monthly_login_count.csv'}
            color="primary"
            style={{ float: 'right', marginRight: '10px' }}
            className="btn btn-primary"
            data={countReport}
          >
            Download CSV
          </CSVLink>
        </Col>
      </Row>
      {/* <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={() => getCountReport(search)}
                >
                  Apply
                </button>
                <button className="close" onClick={() => { getCountReport(blankSearch); setSearch(blankSearch) }}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <DataTable columns={tableHeader} data={countReport} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
    </>
  );
}
