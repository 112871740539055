 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from './admin';
import User from './App';

const App = () => {
  return (
    <Router>
    <Routes>
      <Route
        exact
        path="/admin/*"
        element={<Admin />}
      />{' '}
      <Route
        path="/*"
        element={<User />}
      />
    </Routes>
    </Router>
  )
}

export default App;