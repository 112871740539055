import React, {useEffect, useState} from 'react';

// import './sidebar1.scss';
import './App.css';
import {useNavigate, Link, useLocation} from 'react-router-dom';

function LeftSideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(1);
  const [sidebarActive, setSidebarActive] = useState(0);
  const [isAdmin,setIsAdmin]= useState(false);

  useEffect(()=>{
    checkUser();
  },[])

  const checkUser=()=>{
    let type = localStorage.getItem('institution_id');
    console.log(type)
    if(type !=="null"){
      setIsAdmin(false)
      console.log(type)

    }else{
      setIsAdmin(true)
      console.log(type)

    }
  }

  const openMenuMenu = (number) =>{
    setActiveMenu(number)
    if(number === 1){
      setSidebarActive(0);
      navigate("/admin/dashboard");
    } else if(number === 2){
      setSidebarActive(2);
    } else if(number === 3){
      setSidebarActive(3);
    }else if(number === 4){
      setSidebarActive(4);
    }else if(number === 5){
      setSidebarActive(5);
    }else if(number === 6){
      setSidebarActive(6);
    }else if(number === 7){
      setSidebarActive(7);
    }else if(number === 8){
      setSidebarActive(8);
    }else if(number === 9){
      setSidebarActive(0);
      navigate("/recruiter/logout")
    }else if(number === 10){
      setSidebarActive(10);
    }else if(number === 11){
      setSidebarActive(0);
      navigate("/recruiter/resumes/assigned-resume-list")
    }
  }    

  const logmeOut = () =>{
    localStorage.clear();
    window.location.href = '/admin';
  }  

  const activeLocation = (url, loc) =>{
    if(url.match(loc)) return true;
    return false;
  }

  const activeSubUrl=(url)=>{
    console.log(location.pathname,url)
    if(location.pathname===url){
      return true;
    }

    return false;
  }

  return (
    <div className="new-sidebar-panel">
      <div className="main-sidebar">
        <button className={location?.pathname === "/admin/dashboard" ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(1)}>
          <i className="menu-icon fas fa-tachometer-alt"></i>
          <p>Dashboard</p>
        </button>
          {/* <button className={activeLocation(location?.pathname, "/admin/site_user/") ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(3)}>
          <i className="menu-icon fas fa-users"></i>
          <p>Doctor Users</p>
        </button>
       */}

{isAdmin && (
          <button
            className={activeLocation(location?.pathname, "/admin/site_user/") ? "main-sidebar-btn active" : "main-sidebar-btn"}
            onClick={() => openMenuMenu(3)}
          >
            <i className="menu-icon fas fa-users"></i>
            <p>Doctor Users</p>
          </button>
        )}
        <button className={activeLocation(location?.pathname, "/admin/reports/") ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(2)}>
          <i className="menu-icon fas fa-list-ul"></i>
          <p>Reports</p>
        </button>
        <button className={activeMenu === 9 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => logmeOut()}>
          <i className="menu-icon fas fa-sign-out-alt"></i>
          <p>Logout</p>
        </button>
      </div>
      <div className={sidebarActive === 0 ? "submenu-sidebar" : "submenu-sidebar active"}>
        <button className="sidebar-close-btn" onClick={() => setSidebarActive(0)}><i className="fas fa-times"></i></button>
        <div style={{height:"20px"}}></div>
        {activeMenu === 3 ?
        <>
           <Link to="/admin/site_user/list" 
             className={activeSubUrl("/admin/site_user/list")?"active-submenu":''}
           >Search Doctor Users</Link> 
           <Link to="/admin/site_user/add"
                         className={activeSubUrl("/admin/site_user/add")?"active-submenu":''}

           >Add Doctor User</Link>
             <Link to="/admin/site_user/updatedoctorspassword"
                                        className={activeSubUrl("/admin/site_user/updatedoctorspassword")?"active-submenu":''}

            >Update Password</Link>
             <Link to="/admin/site_user/uploaddata"
                                        className={activeSubUrl("/admin/site_user/uploaddata")?"active-submenu":''}

            >Upload Data</Link> 
           
        </> : null }
        {activeMenu === 2 ?
          <>
            {/* <Link to="/recruiter/candidate/add">Add</Link> */}
            {localStorage.getItem("institution_id") === "3" ? null :
            <Link to="/admin/reports/coupon_usage_report"
             className={activeSubUrl("/admin/reports/coupon_usage_report")?"active-submenu":''}

             >
              Coupon Usage Report</Link> }
              {localStorage.getItem("institution_id") === "1" ? null :
            <Link to="/admin/reports/alembic_registration" 
            className={activeSubUrl("/admin/reports/alembic_registration")?"active-submenu":''}

            >Registration Data</Link> }
            
            

            {isAdmin && (<Link to="/admin/reports/dashboard"
                          className={activeSubUrl("/admin/reports/dashboard")?"active-submenu":''}
            >Dashboard</Link>)}


            {<Link to="/admin/reports/visitsbymonth"
                                        className={activeSubUrl("/admin/reports/visitsbymonth")?"active-submenu":''}

            >Visits By Month</Link>}
            <Link to="/admin/reports/login_count" 
                           className={activeSubUrl("/admin/reports/login_count")?"active-submenu":''}

            >Doctorwise - Monthly login Count</Link>
            {/* {<Link to="/admin/reports/updatedoctorspassword"
                                        className={activeSubUrl("/admin/reports/updatedoctorspassword")?"active-submenu":''}

            >Update Password</Link>}
             {<Link to="/admin/reports/uploaddata"
                                        className={activeSubUrl("/admin/reports/uploaddata")?"active-submenu":''}

            >Upload Data</Link>} */}

            {localStorage.getItem("institution_id") === "1" ? 
            <Link to="/admin/reports/jbcl_registration">JBCL Registration Data</Link> : null }
            
            {/* <Link to="/admin/reports/under_development">Top 10 articles accessed</Link>
            <Link to="/admin/reports/under_development">Top type of content access</Link>
            <Link to="/admin/reports/under_development">Time spent</Link> */}
          </> : null}
          
      </div>
    </div>
  );
}

export default LeftSideBar;
