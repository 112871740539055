import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../customTableStyle';
import { useNavigate } from 'react-router-dom';
import BackButton from "../common/BackButton";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';

export default function ResumeList(props) {

  const navigate = useNavigate();
  const blankSearch = {
    subject_id: "",
    institution_id: "",
    state_id: "",
    city_id: "",
    date_from: '',
    date_to: '',
  }
  const [search, setSearch] = useState("");
  const [mystates, setMyStates] = useState([]);
  const [mycities, setCities] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // setFirstNameError(false);
    // setEmailError(false);
    // setMobileError(false);
    // setStateError(false);
    // setCityError(false);
    // setPincodeError(false);

    if (name === 'state_id') {
      fetchCities(value)
    }
  };

  const [optionPopup, setOptionPopup] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const getArticleList = (req) => {
    axios
      .post(site_ip + '/top10ArticlesByUsageMonth', req)
      .then((res) => {
        // console.log(res);
        setArticleList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getArticleList(blankSearch);
    fetchStates();
    fetchSubjects();
    fetchInstitutions();
  }, []);

  const fetchStates = (e) => {
    fetch(`${site_ip}/getStatesByCountry/1`)
      .then((res) => res.json())
      .then((result) => {
        setMyStates(result.Content)
      });
  }

  const fetchCities = (id) => {
    fetch(`${site_ip}/getCities/` + id)
      .then((res) => res.json())
      .then((result) => {
        setCities(result.Content)
      });
  }

  const [subjects, setSubjects] = useState([])
  const fetchSubjects = (id) => {
    fetch(`${site_ip}/getSubjects`)
      .then((res) => res.json())
      .then((result) => {
        setSubjects(result.Content)
      });
  }

  const [institutions, setInstitutions] = useState([])
  const fetchInstitutions = (id) => {
    fetch(`${site_ip}/getInstitutions`)
      .then((res) => res.json())
      .then((result) => {
        setInstitutions(result.Data)
      });
  }

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Coupon Code',
      selector: (row) => row?.coupon_code,
    },
    {
      name: 'Coupon Redeem Date',
      selector: (row) => row?.coupon_redeem_date,
    },
    {
      name: 'Salutation',
      selector: (row) => row?.salutation,
    },
    {
      name: 'First Name',
      selector: (row) => row?.first_name,
    },
    {
      name: 'Last Name',
      selector: (row) => row?.last_name,
    },
    {
      name: 'Email',
      selector: (row) => row?.user_email,
    },
    {
      name: 'Mobile',
      selector: (row) => row?.user_mobile,
    },
    {
      name: 'State',
      selector: (row) => row?.state_name,
    }
  ]


  const pdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);

    const title = 'Coupon Usage Report'; //QA Daily Report / Current date
    const headers = [
      [
        'Sr No.',
        'Coupon Code',
        'Redeem Date',
        'Saluation',
        'First Name',
        'Last Name',
        'Email',
        'Mobile',
        'State',
      ],
    ];
    const data = articleList.map((elt, index) => [
      index + 1,
      elt.coupon_code,
      elt.coupon_redeem_date,
      elt.salutation,
      elt.first_name,
      elt.last_name,
      elt.user_email,
      elt.user_mobile,
      elt.state_name
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('CouponUsageReport.pdf');
  };

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Top 10 accessed articles"} />
        </Col>
        <Col>
          <button
            onClick={pdf}
            style={{
              float: 'right',
              marginRight: '10px',
              backgroundcolor: 'blue',
            }}
            className="btn btn-primary"
            data={articleList}
          >
            Download PDF
          </button>
          <CSVLink
            filename={'CouponUsageReport.csv'}
            color="primary"
            style={{ float: 'right', marginRight: '10px' }}
            className="btn btn-primary"
            data={articleList}
          >
            Download CSV
          </CSVLink>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      className="customRegisterInput"
                      as="select"
                      name="subject_id"
                      value={search.subject_id}
                      onChange={handleChange}

                    >
                      <option value="">-Select-</option>
                      {subjects?.map((value, index) => (
                        <option key={index} value={value.subject_id}>{value.subject_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Institution</Form.Label>
                    <Form.Control
                      className="customRegisterInput"
                      as="select"
                      name="institution_id"
                      value={search.institution_id}
                      onChange={handleChange}

                    >
                      <option value="">-Select-</option>
                      {institutions?.map((value, index) => (
                        <option key={index} value={value.institution_id}>{value.institute_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      className="customRegisterInput"
                      as="select"
                      name="state_id"
                      value={search.state_id}
                      onChange={handleChange}
                    >
                      <option value="">-Select-</option>
                      {mystates?.map((value, index) => (
                        <option key={index} value={value.state_id}>{value.state_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      className="customRegisterInput"
                      as="select"
                      name="city_id"
                      value={search.city_id}
                      onChange={handleChange}
                    >
                      <option value="">-Select-</option>
                      {mycities?.map((value, index) => (
                        <option key={index} value={value.city_id}>{value.city_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={() => getArticleList(search)}
                >
                  Apply
                </button>
                <button className="close" onClick={() => {getArticleList(blankSearch); setSearch(blankSearch)}}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={tableHeader} data={articleList} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
    </>
  );
}
