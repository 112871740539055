import React,{useState,useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    Form,
    Modal,
  } from 'react-bootstrap';
  import axios from 'axios';
  import { site_ip } from '../../../globalSetting';
  import DataTable from 'react-data-table-component';
  import CustomTableStyle from '../customTableStyle';
  import { useNavigate } from 'react-router-dom';
  import BackButton from "../common/BackButton";
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import { CSVLink } from 'react-csv';
import { Label } from "reactstrap";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Papa from "papaparse";

export default function UploadData() {
  const [csvData, setCSVData] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      complete: (results) => {
        // Access the parsed CSV data
        const data = results.data;
        console.log(data);
        
        // Set the CSV data in the state
        setCSVData(data);
      },
      header: true, // Set this to true if your CSV file has headers
      skipEmptyLines: true, // Skip empty lines while parsing
    });
  };

  const uploadData =()=>{


    axios.post(site_ip+`/auth/addUsers`,csvData).then((res)=>{
        console.log(res,'RESS')
    })
  }

  const tableHeader=[
    {
        name:'#',
        selector:(row,index)=> (index+1),
        width:"100px"
    },
    {
        name:"First Name",
        selector:(row)=>(row.first_name),
        width:"100px"
    },
    {
        name:"Last Name",
        selector:(row)=>(row.last_name),
        width:"100px"
    },
    {
        name:"Email",
        selector:(row)=>(row.user_email),
        width:"200px"
    },
    {
        name:"Brand Name",
        selector:(row)=>(row.brand_name),
        width:"100px"
    },
    {
        name:"Institution ID",
        selector:(row)=>(row.institution_id),
        width:"100px"
    },
    {
        name:"User Name",
        selector:(row)=>(row.ususer_loginer_name),
        width:"200px"
    },
  ]

  return (
    <>
     {/* <div>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      {csvData.length > 0 && (
        <ul>
          {csvData.map((row, index) => (
            <li key={index}>{JSON.stringify(row)}</li>
          ))}
        </ul>
      )}
    </div> */}


<Row style={{ marginBottom: "15px" }}>
        <Col style={{ display: "flex", alignItems: 'center' }}>
          <BackButton title={"Upload Doctors Data "} />
        </Col>
    </Row>
    <Row>
        <Col xl={12} className="mt-3">
            <div className="bg-white">
                <div className="admin-filter-box">
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Upload file</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileChange}
                                />
                            </Form.Group>
                            <button className="success" onClick={uploadData}>
                                Upload Data
                            </button>
                        </Col>
                    
                          
                       
                    </Row>
                    
                </div>
            </div>
        </Col>
    </Row>

    <Row>
        <Col>
            <DataTable
                columns={tableHeader}
                data={csvData}
                fixedHeader
                pagination
                customStyles={CustomTableStyle}
                striped
                highlightOnHover
            />
        </Col>
    </Row>

    </>
   
  );
}
